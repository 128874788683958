import React from "react"
import { Link } from "gatsby"

import Layout from "../../../components/layout"

const imageBaconCornBread = '/tysonscore3.azureedge.net/assets/media/wrightbrand/images/recipes/baconcornbread_large.jpg';

const RecipePage = () => (
    <Layout metaTitle="Bacon Loaded Cornbread Recipe"
        metaDescription="Cornbread is pretty perfect. We thought we’d make it even more perfect by adding our favorite thing: naturally hickory smoked bacon. Try the recipe today!"
        metaKeywords="loaded cornbread"
    >
        <meta property="og:image" content={imageBaconCornBread} />
        <link rel="image_src" href={imageBaconCornBread} />
        <div id="recipe-details-wrapper" >
            <div class="image">
                <img src={imageBaconCornBread} class='img-responsive' alt="Bacon Cornbread" />
            </div>

            <div class="inner-wrapper">
                <div id="recipe-details">
                    <div class="container">
                        <div class="content-wrapper">
                            <h1>Bacon Loaded Cornbread Recipe</h1>

                            <div class="recipe-content">
                                <p >Cornbread is pretty perfect. We thought we’d make it even more perfect by adding our favorite thing: naturally hickory smoked bacon.</p>
                                <p >And we don’t stop there. We load this cornbread to the brim with spicy jalapeño, yellow onion, and sweet honey. But the hearty, smoky goodness is what makes it truly loaded.</p>
                            </div>
                        </div>

                        <div class="timing row">
                            <div class="col-md-4 col-xs-12 time">
                                <div class="icon">
                                    <img src="/tysonscore3.azureedge.net/assets/media/prepIcon.png" alt="Prep Time" />
                                </div>
                                <div class="details">
                                    <h4>Prep Time:</h4>
                                    <p>
                                        10&nbsp;minutes                            </p>
                                </div>
                            </div>

                            <div class="col-md-4 col-xs-12 time">
                                <div class="icon">
                                    <img src="/tysonscore3.azureedge.net/assets/media/cookIcon.png" alt="Cook Time" />
                                </div>
                                <div class="details">
                                    <h4>Cook Time:</h4>
                                    <p>
                                        25&nbsp;minutes                            </p>
                                </div>
                            </div>

                            <div class="col-md-4 col-xs-12 time">
                                <div class="icon">
                                    <img src="/tysonscore3.azureedge.net/assets/media/serveIcon.png" alt="Serving Size" />
                                </div>
                                <div class="details">
                                    <h4>Serving Size:</h4>
                                    <p>4 </p>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>

                <div class="ingredients-wrapper recipe-section">
                    <div class="container">


                        <h2>Ingredients</h2>
                        <div class="row ingredients">
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                10 slices Wright® Brand Naturally Hickory Smoked Bacon <span class="uom"></span>
                            </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                1 package cornbread dry mix (about 8.5 oz.), prepared <span class="uom"></span>
                            </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                3/4 cup cheddar cheese, shredded <span class="uom"></span>
                            </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                1/3 cup jalapeños, fresh, diced <span class="uom"></span>
                            </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                3/4 cup yellow onion, diced <span class="uom"></span>
                            </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                3 tablespoons butter, melted <span class="uom"></span>
                            </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                3 tablespoons honey <span class="uom"></span>
                            </div>
                        </div>


                    </div>
                </div>

                <div class="instructions-wrapper recipe-section">
                    <div class="container">


                        <h2>Directions</h2>
                        <div class="instructions">
                            <p>
                                1. Preheat oven to 375º F. Place 10” cast iron skillet in the oven.
                </p>
                            <p>
                                2. While that gets hot, cook up 10 slices of bacon until crispy (to make crumbling easier). Allow the bacon to cool slightly, crumble bacon into a bowl, and set aside.
                </p>
                            <p>
                                3. In a small mixing bowl, combine cornbread batter and cheddar cheese. Reserve to the side.
                </p>
                            <p>
                                4. In small sauté pan over medium low heat, cook onions and jalapeños until the onions begin to caramelize.
                </p>
                            <p>
                                5. Using oven mitts, remove hot skillet from oven. Evenly distribute bacon, onions and jalapeños across the bottom of the skillet. Pour prepared cornbread mix over sauté blend. Place skillet back into the oven for 4 minutes.
                </p>
                            <p>
                                6. Baste bread with butter and return to oven. Repeat the basting procedure every 2 minutes until the cornbread is golden brown and slightly firm.
                </p>
                            <p>
                                7. Drizzle honey over top before serving.
                </p>
                        </div>

                        <div></div>
                    </div>
                </div>
            </div>
        </div>


        <article class="locator-callout">
            <div class="quick-form-container">


                <div class="product-locator-search">
                    <h2>Find Our Products</h2>
                    <form data-url="/locator/" action="/locator/" method="get">
                        <input Value="ANY_ANY" id="PROD" name="PROD" type="hidden" value="ANY_ANY" />
                        <div class="input-wrapper">
                            <input data-val="true" data-val-regex="Please enter a 5-digit ZIP code" data-val-regex-pattern="^\d{5}" data-val-required="Please enter a 5-digit ZIP code" id="ZIP" maxLength="5" name="ZIP" placeholder="Enter Zip" type="tel" defaultValue="" />
                            <input type="submit" value="Locate Bacon"></input>
                            <span class="field-validation-valid" data-valmsg-for="ZIP" data-valmsg-replace="true"></span>
                        </div>
                    </form>
                </div>

            </div>
        </article>
    </Layout>
)

export default RecipePage
